import React from "react"

const NewsContent = () => {
  return (
    <section
      className="elementor-element elementor-element-4e0b365c elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
      data-id="4e0b365c"
      data-element_type="section"
    >
      <div className="elementor-container elementor-column-gap-default">
        <div className="elementor-row">
          <div
            className="elementor-element elementor-element-364bcf19 elementor-column elementor-col-100 elementor-top-column"
            data-id="364bcf19"
            data-element_type="column"
            data-settings='{"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
          >
            <div className="elementor-column-wrap  elementor-element-populated">
              <div className="elementor-widget-wrap">
                <div
                  className="elementor-element elementor-element-1d3b41f elementor-widget elementor-widget-text-editor"
                  data-id="1d3b41f"
                  data-element_type="widget"
                  data-settings='{"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                  data-widget_type="text-editor.default"
                >
                  <div className="elementor-widget-container">
                    <div className="elementor-text-editor elementor-clearfix">
                      <p>
                        Exercitation photo booth stumptown tote bag Banksy, elit
                        small batch freegan sed. Craft beer elit seitan
                        exercitation, photo booth et 8-bit kale chips proident
                        chillwave deep v laborum.&nbsp;Aliquip veniam delectus,
                        Marfa eiusmod Pinterest&nbsp;in do umami readymade
                        swag.&nbsp;Selfies iPhone Kickstarter, drinking vinegar
                        jean vinegar stumptown&nbsp;yr pop-up artisan.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-319999f elementor-widget elementor-widget-heading"
                  data-id="319999f"
                  data-element_type="widget"
                  data-settings='{"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                  data-widget_type="heading.default"
                >
                  <div className="elementor-widget-container">
                    <h2 className="elementor-heading-title elementor-size-default">
                      A wonderful serenity
                    </h2>
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-7a1117b8 elementor-widget elementor-widget-text-editor"
                  data-id="7a1117b8"
                  data-element_type="widget"
                  data-settings='{"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                  data-widget_type="text-editor.default"
                >
                  <div className="elementor-widget-container">
                    <div className="elementor-text-editor elementor-clearfix">
                      <p>
                        Meh synth Schlitz, tempor duis single-origin coffee ea
                        next level ethnic fingerstache fanny pack nostrud. Photo
                        booth anim 8-bit hella, PBR 3 wolf moon beard Helvetica.
                        Salvia esse nihil, flexitarian Truffaut synth art party
                        deep v chillwave. Seitan High Life reprehenderit
                        consectetur cupidatat kogi. Et leggings fanny pack.
                      </p>
                      <p>
                        Cras chinwag brown bread Eaton cracking goal so I said a
                        load of old tosh baking cakes, geeza arse it’s your
                        round grub sloshed burke, my good sir chancer he legged
                        it he lost his bottle pear shaped bugger all mate.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    // 		</div>
    // 	</div>
    // </div>
    // <br className="clear" />
    // 	</div>
    // </div>
  )
}

export default NewsContent
